<template>
  <div class="boothContent">
    
    <templatePage />
  </div>
</template>

<script>
import templatePage from "~/baseComponents/baseBooth/templatePage";
export default {
  components: { templatePage },
};
</script>

<style scoped lang="less">
.boothContent {
  background: #f2f2f2;
  padding-top: 15px;
  
}
</style>
